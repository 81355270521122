import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormControl, Image, Row, Tab, Tabs } from "react-bootstrap";

import { getCheckBoxField } from "../../helpers/uiHelper";
import { ELandscapeRestorationDataFields } from "../../models/cmsModels";

const LandscapeRestoration = ({useOrgSeedInterest, register}) => {

    return (
        <Row >
            <h3>Landscape Restoration</h3>
            <div className="panel panel-default">
                <div className="panel-body">
                <Col sm={9}>
                    <br></br>
                    <Row>
                        <Form.Label>States where services provided</Form.Label>
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationNT, 'NT', useOrgSeedInterest)}
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationWA, 'WA', useOrgSeedInterest)}
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationSA, 'SA', useOrgSeedInterest)}
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationVIC, 'VIC', useOrgSeedInterest)}
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationTAS, 'TAS', useOrgSeedInterest)}
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationACT, 'ACT', useOrgSeedInterest)}
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationNSW, 'NSW', useOrgSeedInterest)}
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationQLD, 'QLD', useOrgSeedInterest)}
                    </Row>
                </Col>
                </div>
            </div>
        </Row>
    )
}

export default LandscapeRestoration;