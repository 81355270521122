import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "gatsby";
import { Col, Container, Form, FormControl, Image, Row, Tab, Tabs } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import LoadingOverlay from 'react-loading-overlay';

import Search from '../components/shared/Search/search';
import { EOtherServiceDataFields, IUserprofile, IUserprofileSeedInterestData, IUserprofileSubmitData,
    IUserProfileSeedInterests, IOrganisation, IGeoPostCode, IGeoPostCodeResponse } from "../models/cmsModels";
import { EFieldName, EUserProfileSeedInterests }   from "../constants/enums";
import { EUserProfileKeys, ESeedCollectorDataFields, ESeedProductionAreaDataFields,  ESeedTestingDataFields,
 EResearchOrgDataFields, ENurseryDataFields, ELandscapeRestorationDataFields, ESeedBankDataFields } from "../models/cmsModels";
import { cmsCollections, getAllRecords, GetLatLongFromPostcode, getRecordsByParam, SearchRecords, UpdateUserRecord, UploadPhoto } from "../services/cmsService";
import {  IMoodleBadge, IOrganisationSearchResult } from "../models/entityModels";
import SeedProductionArea from "../components/UserProfile/SeedInterest-SeedProductionArea";
import SeedCollector from "../components/UserProfile/SeedInterest-SeedCollector";
import ResearchOrganisation from "../components/UserProfile/SeedInterest-ResearchOrganisation";
import Nursery from "../components/UserProfile/SeedInterest-Nursery";
import LandscapeRestoration from "../components/UserProfile/SeedInterest-LandscapeRestoration";
import SeedBank from "../components/UserProfile/SeedInterest-SeedBank";
import SeedTesting from "../components/UserProfile/SeedInterest-SeedTesting";
import OtherService from "../components/UserProfile/SeedInterest-OtherService";
import { states } from "../constants/data";
import { IFilterItem } from "../components/shared/FilterDropdown/filterDropdown";
import Layout from "../components/Layout";
import { getIsUserAuthenticated, getMSALAccessToken, getToken, getUser, isBrowser } from "../services/auth";
import { loaderStyle, waitingText } from '../constants/common';
import { getUserBadgeDetails, retrieveBadge } from "../services/moodleService";
import ToastContainer, { EMessageModeEnum } from "../components/shared/Toast/ToastContainer";
import MoodleBadge from "../components/shared/Badge/MoodleBadge";
import defaultAvatar from "../assets/images/defaultAvatar.jpeg";
import { getCommunicationPreferences, setCommunicationPreferences } from '../services/mailchimpService';

import "../assets/css/main.scss";

const UserProfile = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ searchResult, setSearchResult  ] = useState([] as IOrganisationSearchResult[])
    const [ userprofile, setUserProfile ] = useState(undefined as IUserprofile);
    const [ noSuggestions, setNoSuggestions ] = useState(Boolean(false));
    const [ selectedOrg, setSelectedOrg ] = useState("");
    const [ selectedOrgId, setSelectedOrgId ] = useState("");
    const [ selectedFile, setSelectedFile ] = useState(null);
    const [ userProfilePhoto, setUserProfilePhoto ] = useState("");
    const [ photoUploadId, setPhotoUploadId ] = useState(null);
    const [ userID, setUserID ] = useState(null);
    const [ badgeJSX, setbadgeJSX ] = useState(null);
    const [ key, setKey ] = useState('contactInfo');
    const [ message, setMessage ] = useState('');
    const [ messageMode, setMessageMode ] = useState(EMessageModeEnum.SUCCESS);
    const { register, handleSubmit, setValue, getValues } = useForm();

    // for storing the user's seedInterests (level 1 checkboxes)
    const [ useOrgSeedInterest, setUseOrgSeedInterest ] = useState(Boolean);

    // level 2 checkboxes
    const [ seedInterestFlags, setSeedInterestFlags ] = useState({} as IUserProfileSeedInterests);
    
    const isAuthenticated = getIsUserAuthenticated();
    
    let token = getToken();

    useEffect(() => {
        if (!isAuthenticated && isBrowser()) {
            window.location.href = '/';
        }

        const fn = async () => {            
            token = getToken();

            if (!userprofile && token) {
                //const data: IUserprofile = await getRecord('', cmsCollections.Userprofiles, user.id).then(res => res ? res.data : {});
                setIsLoading(true);
                const user = getUser();
                const results = await getRecordsByParam(token, cmsCollections.Userprofiles, "email", user.email);
                setIsLoading(false);

                let profiles: any;
                if (results?.userprofiles.length) {
                    profiles = results?.userprofiles;
                }
                else {
                    profiles = [user];
                }
                const data: IUserprofile = profiles && profiles.length ? profiles[profiles.length - 1] : null;
        
                setUserProfile(data);
            
                if(data) {
                    // set userID	
                    setUserID(data.id);

                    // tab 1
                    setValue(EUserProfileKeys.firstname, data.firstname); 
                    setValue(EUserProfileKeys.lastname, data.lastname); 
                    setValue(EUserProfileKeys.email, data.email); 
                    setValue(EUserProfileKeys.mobilenumber, data.mobilenumber); 
                    setValue(EUserProfileKeys.organisation, data.organisation); 
                    data.organisation ? setSelectedOrg(data.organisation) : setSelectedOrg("")
                    setValue(EUserProfileKeys.suburb, data.suburb); 
                    data.state ? setValue(EUserProfileKeys.state, data.state) : setValue(EUserProfileKeys.state, "Select your state")
                    setValue(EUserProfileKeys.postcode, data.postcode); 
                    setValue(EUserProfileKeys.isUserVisibile, data.isUserVisibile); 

                    // tab 2 - useform
                    setValue(EUserProfileKeys.useOrgSeedInterest, data.useOrgSeedInterest);
                    setValue(EUserProfileKeys.isSeedCollector, data.isSeedCollector);
                    setValue(EUserProfileKeys.isResearchOrganisation, data.isResearchOrganisation);
                    setValue(EUserProfileKeys.isSeedBank, data.isSeedBank);
                    setValue(EUserProfileKeys.isSeedProductionArea, data.isSeedProductionArea);
                    setValue(EUserProfileKeys.isLandscapeRestoration, data.isLandscapeRestoration);
                    setValue(EUserProfileKeys.isOther, data.isOther);
                    setValue(EUserProfileKeys.isNursery, data.isNursery);
                    setValue(EUserProfileKeys.isSeedTesting, data.isSeedTesting);
                    
                    // set the checkbox flags
                    setSeedInterestFlags(data);

                    // store in local state - for use in checkbox panel for seed interest (if disabled or not)
                    setUseOrgSeedInterest(data.useOrgSeedInterest);

                    const seedCollectorData = data.userData?.seedCollectorData[0];
                    if (seedCollectorData) {
                        setValue(ESeedCollectorDataFields.seedCollectorCanopyTree, seedCollectorData.seedCollectorCanopyTree)
                        setValue(ESeedCollectorDataFields.seedCollectorGroundLayer, seedCollectorData.seedCollectorGroundLayer)
                        setValue(ESeedCollectorDataFields.seedCollectorOther, seedCollectorData.seedCollectorOther)
                        setValue(ESeedCollectorDataFields.seedCollectorSeedMidStory, seedCollectorData.seedCollectorSeedMidStory)
                        setValue(ESeedCollectorDataFields.seedCollectorWetLand, seedCollectorData.seedCollectorWetLand)
                        setValue(ESeedCollectorDataFields.seedCollectorLocation, seedCollectorData.seedCollectorLocation)
                        setValue(ESeedCollectorDataFields.seedCollectorDistance, seedCollectorData.seedCollectorDistance)
                        setValue(ESeedCollectorDataFields.seedCollectorMonths, seedCollectorData.seedCollectorMonths)
                        setValue(ESeedCollectorDataFields.seedCollectorSpecialistCleaning, seedCollectorData.seedCollectorSpecialistCleaning)
                        setValue(ESeedCollectorDataFields.seedCollectorSpecialistStorage, seedCollectorData.seedCollectorSpecialistStorage)
                        setValue(ESeedCollectorDataFields.seedCollectorServiceExpertiseDescription, seedCollectorData.seedCollectorServiceExpertiseDescription)
                    }
                    
                    const seedProductionAreaData = data.userData?.seedProductionAreaData?.length && data.userData?.seedProductionAreaData[0];
                    if (seedProductionAreaData) {
                        setValue(ESeedProductionAreaDataFields.seedProductionAreaCanopyTree, seedProductionAreaData.seedProductionAreaCanopyTree)
                        setValue(ESeedProductionAreaDataFields.seedProductionAreaGroundLayer, seedProductionAreaData.seedProductionAreaGroundLayer)
                        setValue(ESeedProductionAreaDataFields.seedProductionAreaOther, seedProductionAreaData.seedProductionAreaOther)
                        setValue(ESeedProductionAreaDataFields.seedProductionAreaSeedMidStory, seedProductionAreaData.seedProductionAreaSeedMidStory)
                        setValue(ESeedProductionAreaDataFields.seedProductionAreaWetLand, seedProductionAreaData.seedProductionAreaWetLand)
                        setValue(ESeedProductionAreaDataFields.seedProductionAreaLocation, seedProductionAreaData.seedProductionAreaLocation)
                        setValue(ESeedProductionAreaDataFields.seedProductionAreaDistance, seedProductionAreaData.seedProductionAreaDistance)
                        setValue(ESeedProductionAreaDataFields.seedProductionAreaMonths, seedProductionAreaData.seedProductionAreaMonths)
                        setValue(ESeedProductionAreaDataFields.seedProductionAreaSpecialistCleaning, seedProductionAreaData.seedProductionAreaSpecialistCleaning)
                        setValue(ESeedProductionAreaDataFields.seedProductionAreaSpecialistStorage, seedProductionAreaData.seedProductionAreaSpecialistStorage)
                        setValue(ESeedProductionAreaDataFields.seedProductionAreaServiceExpertiseDescription, seedProductionAreaData.seedProductionAreaServiceExpertiseDescription)
                    }

                    const researchOrganisationData = data.userData?.researchOrganisationData?.length && data.userData?.researchOrganisationData[0];
                    if (researchOrganisationData) {
                        setValue(EResearchOrgDataFields.ResearchOrgDescription, researchOrganisationData.researchOrganisationDescription)
                    }

                    const nurserydata = data.userData?.nurseryData?.length && data.userData?.nurseryData[0] ;
                    if (nurserydata) {
                        setValue(ENurseryDataFields.nurseryServiceInNT, nurserydata.nurseryServiceInNT)
                        setValue(ENurseryDataFields.nurseryServiceInWA, nurserydata.nurseryServiceInWA)
                        setValue(ENurseryDataFields.nurseryServiceInSA, nurserydata.nurseryServiceInSA)
                        setValue(ENurseryDataFields.nurseryServiceInVIC, nurserydata.nurseryServiceInVIC)
                        setValue(ENurseryDataFields.nurseryServiceInTAS, nurserydata.nurseryServiceInTAS)
                        setValue(ENurseryDataFields.nurseryServiceInACT, nurserydata.nurseryServiceInACT)
                        setValue(ENurseryDataFields.nurseryServiceInNSW, nurserydata.nurseryServiceInNSW)
                        setValue(ENurseryDataFields.nurseryServiceInQLD, nurserydata.nurseryServiceInQLD)
                        setValue(ENurseryDataFields.nurseryCapacity, nurserydata.nurseryCapacity)
                    }

                    const landscapeRestorationData = data.userData?.landscapeRestorationData?.length && data.userData?.landscapeRestorationData[0] ;
                    if (landscapeRestorationData) {
                        setValue(ELandscapeRestorationDataFields.landscapeRestorationNT, landscapeRestorationData.landscapeRestorationNT)
                        setValue(ELandscapeRestorationDataFields.landscapeRestorationWA, landscapeRestorationData.landscapeRestorationWA)
                        setValue(ELandscapeRestorationDataFields.landscapeRestorationSA, landscapeRestorationData.landscapeRestorationSA)
                        setValue(ELandscapeRestorationDataFields.landscapeRestorationVIC, landscapeRestorationData.landscapeRestorationVIC)
                        setValue(ELandscapeRestorationDataFields.landscapeRestorationTAS, landscapeRestorationData.landscapeRestorationTAS)
                        setValue(ELandscapeRestorationDataFields.landscapeRestorationACT, landscapeRestorationData.landscapeRestorationACT)
                        setValue(ELandscapeRestorationDataFields.landscapeRestorationNSW, landscapeRestorationData.landscapeRestorationNSW)
                        setValue(ELandscapeRestorationDataFields.landscapeRestorationQLD, landscapeRestorationData.landscapeRestorationQLD)
                    }

                    const seedBankData = data.userData?.seedBankData?.length && data.userData?.seedBankData[0] ;
                    if (seedBankData) {
                        setValue(ESeedBankDataFields.seedBankNT, seedBankData.seedBankNT)
                        setValue(ESeedBankDataFields.seedBankWA, seedBankData.seedBankWA)
                        setValue(ESeedBankDataFields.seedBankSA, seedBankData.seedBankSA)
                        setValue(ESeedBankDataFields.seedBankVIC, seedBankData.seedBankVIC)
                        setValue(ESeedBankDataFields.seedBankTAS, seedBankData.seedBankTAS)
                        setValue(ESeedBankDataFields.seedBankACT, seedBankData.seedBankACT)
                        setValue(ESeedBankDataFields.seedBankNSW, seedBankData.seedBankNSW)
                        setValue(ESeedBankDataFields.seedBankQLD, seedBankData.seedBankQLD)
                        setValue(ESeedBankDataFields.seedBankDescription, seedBankData.seedBankDescription)
                    }

                    const seedTestingData = data.userData?.seedTestingData?.length && data.userData?.seedTestingData[0];
                    if (seedTestingData) {
                        setValue(ESeedTestingDataFields.seedTestingNT, seedTestingData.seedTestingNT)
                        setValue(ESeedTestingDataFields.seedTestingWA, seedTestingData.seedTestingWA)
                        setValue(ESeedTestingDataFields.seedTestingSA, seedTestingData.seedTestingSA)
                        setValue(ESeedTestingDataFields.seedTestingVIC, seedTestingData.seedTestingVIC)
                        setValue(ESeedTestingDataFields.seedTestingTAS, seedTestingData.seedTestingTAS)
                        setValue(ESeedTestingDataFields.seedTestingACT, seedTestingData.seedTestingACT)
                        setValue(ESeedTestingDataFields.seedTestingNSW, seedTestingData.seedTestingNSW)
                        setValue(ESeedTestingDataFields.seedTestingQLD, seedTestingData.seedTestingQLD)
                    }

                    const otherServiceData = data.userData?.otherServiceData?.length && data.userData?.otherServiceData[0] ;
                    if (otherServiceData) {
                        setValue(EOtherServiceDataFields.otherServiceNT, otherServiceData.otherServiceNT)
                        setValue(EOtherServiceDataFields.otherServiceWA, otherServiceData.otherServiceWA)
                        setValue(EOtherServiceDataFields.otherServiceSA, otherServiceData.otherServiceSA)
                        setValue(EOtherServiceDataFields.otherServiceVIC, otherServiceData.otherServiceVIC)
                        setValue(EOtherServiceDataFields.otherServiceTAS, otherServiceData.otherServiceTAS)
                        setValue(EOtherServiceDataFields.otherServiceACT, otherServiceData.otherServiceACT)
                        setValue(EOtherServiceDataFields.otherServiceNSW, otherServiceData.otherServiceNSW)
                        setValue(EOtherServiceDataFields.otherServiceQLD, otherServiceData.otherServiceQLD)
                        setValue(EOtherServiceDataFields.otherServiceDescription, otherServiceData.otherServiceDescription)
                    }

                    setValue('userNewsletterSubscribe', data.userNewsletterSubscribe);

                    // if the image exists (and not undefined)
                    if (data.userPhoto) {
                        setUserProfilePhoto(data.userPhoto.url);
                    }

                    // if moodleuserId is set
                    if(data.moodleuserid) {
                        let badgeDetailsResponse = await getUserBadgeDetails('', data.moodleuserid);
                        let badges = badgeDetailsResponse?.data.badges;

                        if (badges) {
                            let badgeJSX = [];
                            for (let badgeElement = 0; badgeElement < badges.length; badgeElement++) {
                                
                                const getBadgeResponse = await retrieveBadge(badges[badgeElement].badgeurl);
                                if (getBadgeResponse?.length>0) {
                                    let badgeData : IMoodleBadge = {
                                        b64data: getBadgeResponse,
                                        name: badges[badgeElement].name
                                    }
                                    badgeJSX.push(
                                        <MoodleBadge badge={badgeData} />
                                        
                                    )
                                } else {
                                    showMessage('Error while trying to retrive the badges', EMessageModeEnum.ERROR);
                                }
                            }
                            setbadgeJSX(badgeJSX);
                        } else {
                            showMessage('Error while trying to retrive the badges', EMessageModeEnum.ERROR);
                        }
                    }
                    
                    if (data.email) {
                        const accessToken = await getMSALAccessToken();
                        const subscription = await getCommunicationPreferences(accessToken, data.email);
                        console.log('subscription === ', subscription)
                        setValue(EUserProfileKeys.userNewsletterSubscribed, subscription?.subscribed || false);
                    }
                }
            }
        };
    
        fn();
    }, [isAuthenticated]);

    let timeout: any;

    const showMessage = (message: string, mode: EMessageModeEnum) => {
        setMessage(message);
        setMessageMode(mode);
    };

    const toggleSeedInterestFlag = (name: string) => {
        const val = seedInterestFlags[name]
        setSeedInterestFlags({ ...seedInterestFlags, [name] : !val});
    };

    const getCheckBoxField = (seedInterestField: string) => {
        return (
            <Form.Group controlId={EFieldName[seedInterestField]}>
                <Form.Check type="checkbox" disabled={useOrgSeedInterest} 
                onClick={() => toggleSeedInterestFlag(seedInterestField)} 
                {...register(seedInterestField)} name={seedInterestField} label={EFieldName[seedInterestField]} />
            </Form.Group>
        )
    }

    const handleFileChange = async (event: any) => {
        event.preventDefault();
        let files = event.target.files;
        setSelectedFile(files);
        console.log("jm: setSelectedFile ", files);
    }


    const onSubmit = async (data: IUserprofileSubmitData) => {
        // get postcodes
        const postCodeData: IGeoPostCodeResponse = await GetLatLongFromPostcode(data.postcode).then(res => res ? res.data : []);

        // get the keyval for specific postcode 
        const latlongData : IGeoPostCode = postCodeData.isSuccess ? postCodeData.postcodes[0] : {lat: "", long: ""};

        // for profile photo
        let photoUploadResponse = undefined;
        if (selectedFile) {
            setIsLoading(true);
            photoUploadResponse = await UploadPhoto(token, {"files": selectedFile});
            photoUploadResponse = photoUploadResponse ? photoUploadResponse?.data.length && photoUploadResponse?.data[0].id : null;
            setPhotoUploadId(photoUploadResponse);
            setIsLoading(false);
        }

        
        

        const seedCollectorData = {
            seedCollectorCanopyTree: data.seedCollectorCanopyTree,
            seedCollectorGroundLayer: data.seedCollectorGroundLayer,
            seedCollectorOther: data.seedCollectorOther,
            seedCollectorSeedMidStory: data.seedCollectorSeedMidStory,
            seedCollectorWetLand: data.seedCollectorWetLand,
            seedCollectorLocation: data.seedCollectorLocation,
            seedCollectorDistance: data.seedCollectorDistance,
            seedCollectorMonths: data.seedCollectorMonths,
            seedCollectorSpecialistCleaning: data.seedCollectorSpecialistCleaning,
            seedCollectorSpecialistStorage: data.seedCollectorSpecialistStorage,
            seedCollectorServiceExpertiseDescription: data.seedCollectorServiceExpertiseDescription
        }

        const seedProductionAreaData = {
            seedProductionAreaCanopyTree: data.seedProductionAreaCanopyTree,
            seedProductionAreaGroundLayer: data.seedProductionAreaGroundLayer,
            seedProductionAreaSeedMidStory: data.seedProductionAreaSeedMidStory,
            seedProductionAreaWetLand: data.seedProductionAreaWetLand,
            seedProductionAreaOther: data.seedProductionAreaOther,
            seedProductionAreaLocation: data.seedProductionAreaLocation,
            seedProductionAreaDistance: data.seedProductionAreaDistance,
            seedProductionAreaMonths: data.seedProductionAreaMonths,
            seedProductionAreaSpecialistCleaning: data.seedProductionAreaSpecialistCleaning,
            seedProductionAreaSpecialistStorage: data.seedProductionAreaSpecialistStorage,
            seedProductionAreaServiceExpertiseDescription: data.seedProductionAreaServiceExpertiseDescription,
        }

        const researchOrganisationData = {
            researchOrganisationDescription: data.ResearchOrgDescription,
        }

        const nurserydata = {
            nurseryServiceInNT: data.nurseryServiceInNT,
            nurseryServiceInWA: data.nurseryServiceInWA,
            nurseryServiceInSA: data.nurseryServiceInSA,
            nurseryServiceInVIC: data.nurseryServiceInVIC,
            nurseryServiceInTAS: data.nurseryServiceInTAS,
            nurseryServiceInACT: data.nurseryServiceInACT,
            nurseryServiceInNSW: data.nurseryServiceInNSW,
            nurseryServiceInQLD: data.nurseryServiceInQLD,
            nurseryCapacity: data.nurseryCapacity,
        }

        const landscapeRestorationData = {
            landscapeRestorationNT: data.landscapeRestorationNT,
            landscapeRestorationWA: data.landscapeRestorationWA,
            landscapeRestorationSA: data.landscapeRestorationSA,
            landscapeRestorationVIC: data.landscapeRestorationVIC,
            landscapeRestorationTAS: data.landscapeRestorationTAS,
            landscapeRestorationACT: data.landscapeRestorationACT,
            landscapeRestorationNSW: data.landscapeRestorationNSW,
            landscapeRestorationQLD: data.landscapeRestorationQLD,
        }

        const seedBankData = {
            seedBankNT: data.seedBankNT,
            seedBankWA: data.seedBankWA,
            seedBankSA: data.seedBankSA,
            seedBankVIC: data.seedBankVIC,
            seedBankTAS: data.seedBankTAS,
            seedBankACT: data.seedBankACT,
            seedBankNSW: data.seedBankNSW,
            seedBankQLD: data.seedBankQLD,
            seedBankDescription: data.seedBankDescription,
        }

        const seedTestingData = {
            seedTestingNT: data.seedTestingNT,
            seedTestingWA: data.seedTestingWA,
            seedTestingSA: data.seedTestingSA,
            seedTestingVIC: data.seedTestingVIC,
            seedTestingTAS: data.seedTestingTAS,
            seedTestingACT: data.seedTestingACT,
            seedTestingNSW: data.seedTestingNSW,
            seedTestingQLD: data.seedTestingQLD,
        }

        const otherServiceData = {
            otherServiceNT: data.otherServiceNT,
            otherServiceWA: data.otherServiceWA,
            otherServiceSA: data.otherServiceSA,
            otherServiceVIC: data.otherServiceVIC,
            otherServiceTAS: data.otherServiceTAS,
            otherServiceACT: data.otherServiceACT,
            otherServiceNSW: data.otherServiceNSW,
            otherServiceQLD: data.otherServiceQLD,
            otherServiceDescription: data.otherServiceDescription,
        }

        const userData : IUserprofileSeedInterestData = {
            seedCollectorData: [seedCollectorData],
            seedProductionAreaData: [seedProductionAreaData],
            researchOrganisationData: [researchOrganisationData],
            nurseryData: [nurserydata],
            landscapeRestorationData: [landscapeRestorationData],
            seedBankData: [seedBankData],
            seedTestingData: [seedTestingData],
            otherServiceData: [otherServiceData]
        }

        const payload : IUserprofile = {
            id: userID,
            userData : userData,
            organisation : selectedOrg,
            organisationId: selectedOrgId,

            // set geopostcode vals
            lat: latlongData.lat,
            long: latlongData.long,
            userPhoto: photoUploadResponse,
            ...data
        }
        
        const successFn = () => {
            showMessage('Your Profile has been updated!', EMessageModeEnum.SUCCESS);
            moveNext();    
        };
        setIsLoading(true);
        const result = await UpdateUserRecord(token, cmsCollections.Userprofiles, payload, successFn);
        setIsLoading(false);
    };

    const updatePreferences = async () => {
        const user = getValues();
        const firstName = user[EUserProfileKeys.firstname];
        const lastName = user[EUserProfileKeys.lastname];

        if (!firstName || !lastName) {
            showMessage('First Name and Last Name cannot not be empty.', EMessageModeEnum.ERROR);
            return;
        }

        const flag = user[EUserProfileKeys.userNewsletterSubscribed];
        setIsLoading(true);
        let token = await getMSALAccessToken();
        const endFn = () => setIsLoading(false);
        const response = await setCommunicationPreferences(token, userprofile.email, firstName, lastName, flag, endFn);
        
        if (response?.status === 0) {
            showMessage('Subscription information has been updated!', EMessageModeEnum.SUCCESS);
            moveNext();
        }
        else {
            showMessage(response.message, EMessageModeEnum.ERROR);
        }
    };

    const moveNext = () => {
        // if the user is already on seedInterests tab, and hits update, then take user to commPrefs
        if (key === "seedInterests") {
            setKey("commPrefs")
        // if the user is already on commPrefs tab, and hits update, then take user to badges
        } else if (key === "commPrefs") {
            setKey("badges")
        } else {
        // if the user is contactInfo tab, and hits update, then take user to seedInterests
            setKey("seedInterests")
        }
    };

    const applySearchTerm = async (term: string) => {
        const isInputBlank = term.trim() === '';
        if (isInputBlank) {
            setNoSuggestions(false);
        }

        if (!isInputBlank) {
            setIsLoading(true);
            const data = await SearchRecords('', cmsCollections.Organisations, term, 10).then((res: any) => res ? res.data : []);
            setIsLoading(false);

            setNoSuggestions(!isInputBlank && data.length === 0);
            const data_filtered_with_id_text = data.map(function(row) { return { id: row.id, text: row.orgName} });
            setSearchResult(data_filtered_with_id_text);
        }
    };

    const setOrgSeedInterestsToUser = async () => {
        

        // if userprofile is set
        if(userprofile) {
            // get data for the user's organisation - potential for bug if user did not have org selected
            const userOrgData: IOrganisation[] = await getAllRecords('', cmsCollections.Organisations).then(res => res ? res.data : []);
            const orgData : IOrganisation = userOrgData.filter(org => org.orgName == userprofile[0].organisation)[0]
            
            // patch with the org's preferences
            const updateUserProfilePayload = {
                id: userprofile[0].id, 
                isSeedCollector: orgData.isSeedCollector,
                isResearchOrganisation: orgData.isResearchOrganisation,
                isSeedBank: orgData.isSeedBank,
                isSeedProductionArea: orgData.isSeedProductionArea,
                isLandscapeRestoration: orgData.isLandscapeRestoration,
                isOther: orgData.isOther,
                isNursery: orgData.isNursery,
                isSeedTesting: orgData.isSeedTesting,
            }

            setIsLoading(true);
            const result_updateUser = await UpdateUserRecord(token, cmsCollections.Userprofiles, updateUserProfilePayload);
            setIsLoading(false);
        }
    }
  
    return (
        <>
        {!isAuthenticated ? <></> :
            <Layout>
                <ToastContainer message={message} mode={messageMode} />
                {
                    <Container className="user-profile fl-page">
                        <h3>Manage Profile</h3>
                        <LoadingOverlay active={isLoading} spinner text={waitingText} styles={loaderStyle}>
                            <Tabs defaultActiveKey="contactInfo" id="manage-profile" activeKey={key} onSelect={(k) => setKey(k)}>
                                <Tab eventKey="contactInfo" title="Contact Info">
                                    <Row>
                                        <Col>
                                            <Form onSubmit={handleSubmit(onSubmit)} autoComplete="chrome-off" >
                                                <input autoComplete="false" name="hidden" style={{display: "none"}} type="text" />
                                                <br></br>
                                                <h4>My Account</h4>
                                                <Row>
                                                    <Col sm={9}>
                                                        <Row>
                                                            <Col className="mx-auto">
                                                                <div >
                                                                    <Form.Label>Profile Picture</Form.Label>
                                                                    <Form.Group controlId="formProfilePicture">  
                                                                        <img onClick={() => document.getElementById("mediafiles").click()} 
                                                                            title="Click to change" 
                                                                            style={{cursor: "pointer", "borderRadius": "50%"}}
                                                                            width="100px"
                                                                            height="100px"

                                                                            // {/* if no photo is set, then show default, else display it */}
                                                                            src={ userProfilePhoto === "" ? defaultAvatar : userProfilePhoto }
                                                                            />
                                                                    </Form.Group>
                                                                    <br>
                                                                    </br>
                                                                    
                                                                    
                                                                    <div style={{display: "none"}}>
                                                                        <Form.File name="file" label="Select file(s)" custom onChange={handleFileChange} id="mediafiles" feedbackTooltip />
                                                                    </div>
                                                                    
                                                                </div>
                                                            </Col>
                                                        </Row>
                                
                                                        <Row>
                                                            <Col sm={8}>
                                                                <Form.Group controlId="formEmail">
                                                                    <Form.Label>Email address</Form.Label>
                                                                    <FormControl type="text" name="email" placeholder="Enter your email address" {...register(EUserProfileKeys.email)} disabled />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <br></br>
                                                        <Row >
                                                            <Col sm={4}>
                                                                <Form.Group controlId="formFirstNameFirstName">
                                                                    <Form.Label>First Name</Form.Label>
                                                                    <FormControl type="text" name="firstname" placeholder="Enter Firstname" {...register(EUserProfileKeys.firstname)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <Form.Group controlId="formFirstNameLastName">
                                                                    <Form.Label>Last Name</Form.Label>
                                                                    <FormControl type="text" name="lastname" placeholder="Enter Lastname" {...register(EUserProfileKeys.lastname)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <br></br>
                                                        <Row>
                                                            <Col sm={8}>
                                                                <Form.Group controlId="formMobile">
                                                                    <Form.Label>Mobile Number</Form.Label>
                                                                    <FormControl type="number" name="mobilenumber" placeholder="Enter your mobile number" {...register(EUserProfileKeys.mobilenumber)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <br></br>
                                                        <Row>
                                                            <Col sm={8}>
                                                                <Form.Group controlId="formState">
                                                                    <Form.Label>State</Form.Label>
                                                                    <Form.Control as="select" {...register(EUserProfileKeys.state)}>
                                                                        <option disabled>Select your state</option>
                                                                        {
                                                                            states.map((state: IFilterItem) => (
                                                                                <option>{state.key}</option>
                                                                            ))
                                                                        }
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <br></br>
                                                        <Row>
                                                            <Col sm={8}>
                                                                <Form.Group controlId="formSuburb">
                                                                    <Form.Label>Suburb</Form.Label>
                                                                    <FormControl type="text" name="suburb" placeholder="Enter your suburb" {...register(EUserProfileKeys.suburb)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <br></br>
                                                        <Row>
                                                            <Col sm={8}>
                                                                <Form.Group controlId="formOrg">
                                                                    <Form.Label>Post Code</Form.Label>
                                                                    <FormControl type="text" name="postcode" placeholder="Enter your post code" {...register(EUserProfileKeys.postcode)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <br></br>
                                                        <Row>
                                                            <Col sm={8}>
                                                                <Form.Group controlId="formOrg">
                                                                    <Form.Label>Organisation</Form.Label>
                                                                        {
                                                                            selectedOrg !== "" ? 
                                                                            <FormControl type="text" name="organisation" value={selectedOrg}  />
                                                                            : 
                                                                            <Search key="search"
                                                                                onSearchByTerm={applySearchTerm}
                                                                                onSelection={
                                                                                    (value) => { 
                                                                                        setSelectedOrg(value.text); 
                                                                                        setSelectedOrgId(value.id);
                                                                                    } 
                                                                                }
                                                                                placeHolder="Search for your organisation..."
                                                                                onGetAutoSuggestions={() => {
                                                                                    return searchResult
                                                                                }}
                                                                                minTermLength={1}
                                                                            />
                                                                        }
                                                                        <div className="no-suggestions">
                                                                            <p>Dont see your organisation? <Link to={`/neworg`} >Click here to add one</Link></p>
                                                                            <p>Please note that you will need to belong to an organisation, even if you are a sole trader.</p>
                                                                        </div>

                                                                    <br></br>
                                                                    {
                                                                        // show the button to change org only if org is already set
                                                                        selectedOrg !== "" ? 
                                                                        <button className="btn btn-warning" onClick={ () => setSelectedOrg("") }>Change Org</button>
                                                                        : <></>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <br></br>
                                                        <Row>
                                                            <Col sm={8}>
                                                                <Form.Group controlId="formUserVisibility">
                                                                    <Form.Label>Seed People Visibility</Form.Label>
                                                                    <Form.Check  {...register(EUserProfileKeys.isUserVisibile)} name="isUserVisibile" id="isUserVisibile"  type="checkbox" label="Show my profile on Seed Directory?" />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>

                                                        <br></br>
                                                        <button className="btn btn-primary" type="submit">Update Contact Info</button>
                                                    </Col>
                                                </Row>
                                                <br></br>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Tab>

                                <Tab eventKey="seedInterests" title="Seed Interests">
                                    <Row>
                                        <Col>
                                            <Form onSubmit={handleSubmit(onSubmit)}>
                                                <br></br>
                                                <h4>My Seed Interests</h4>
                                                <Row>
                                                    <Col sm={9}>
                                                        <br></br>
                                                        <Row>
                                                            <Form.Group controlId="formUserOrgSeedInterest">
                                                                <Form.Check type="checkbox" onClick={() => {
                                                                        
                                                                        // if user has not already set to useorg interests then on click show dialogue
                                                                        if (!useOrgSeedInterest) {
                                                                            if (confirm("Are you sure? this will override your seed preferences")) {
                                                                                setUseOrgSeedInterest(!useOrgSeedInterest);
                                                                                setOrgSeedInterestsToUser();
                                                                            } else {
                                                                                // do nothing
                                                                            }
                                                                        } else {
                                                                            setUseOrgSeedInterest(!useOrgSeedInterest);
                                                                        }
                                                                    }
                                                                }  {...register(EUserProfileKeys.useOrgSeedInterest)} name="useOrgSeedInterest" label="Use my organisation’s seed interests" />
                                                            </Form.Group>
                                                            <Form.Label>Check this box to make your seed interests the same as your organisation’s seed interests</Form.Label>
                                                            <p>Please note: if you are a sole trader, you would have set your seed interests while creating your own organisation, so check the above box to avoid filling your seed interests again.</p>
                                                        </Row>

                                                        <br></br>
                                                        <Row >
                                                            <div className="panel panel-default">
                                                                <div className="panel-body">
                                                                    {getCheckBoxField(EUserProfileSeedInterests.isSeedCollector)}
                                                                    {getCheckBoxField(EUserProfileSeedInterests.isResearchOrganisation)}
                                                                    {getCheckBoxField(EUserProfileSeedInterests.isSeedBank)}
                                                                    {getCheckBoxField(EUserProfileSeedInterests.isSeedProductionArea)}
                                                                    {getCheckBoxField(EUserProfileSeedInterests.isLandscapeRestoration)}
                                                                    {getCheckBoxField(EUserProfileSeedInterests.isOther)}
                                                                    {getCheckBoxField(EUserProfileSeedInterests.isNursery)}
                                                                    {getCheckBoxField(EUserProfileSeedInterests.isSeedTesting)}

                                                                </div>
                                                                <div className="row justify-content-end">
                                                                    <div className="col-4">
                                                                        Select as many as required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                        <br></br>
                                                        
                                                        <div style={ seedInterestFlags.isSeedCollector ? {display: ''} : {display: 'none'}}>
                                                            <SeedCollector useOrgSeedInterest={useOrgSeedInterest} register={register} />
                                                        </div>
                                                        <br></br>
                                                        <div style={ seedInterestFlags.isSeedProductionArea ? {display: ''} : {display: 'none'}}>
                                                            <SeedProductionArea useOrgSeedInterest={useOrgSeedInterest} register={register} />
                                                        </div>      
                                                        <br></br>
                                                        <div style={ seedInterestFlags.isResearchOrganisation ? {display: ''} : {display: 'none'}}>
                                                            <ResearchOrganisation useOrgSeedInterest={useOrgSeedInterest} register={register} />
                                                        </div>   
                                                        <br></br>
                                                        <div style={ seedInterestFlags.isNursery ? {display: ''} : {display: 'none'}}>
                                                            <Nursery useOrgSeedInterest={useOrgSeedInterest} register={register} />
                                                        </div>       
                                                        <br></br>
                                                        <div style={ seedInterestFlags.isLandscapeRestoration ? {display: ''} : {display: 'none'}}>
                                                            <LandscapeRestoration useOrgSeedInterest={useOrgSeedInterest} register={register} />
                                                        </div>     
                                                        <br></br>
                                                        <div style={ seedInterestFlags.isSeedBank ? {display: ''} : {display: 'none'}}>
                                                            <SeedBank useOrgSeedInterest={useOrgSeedInterest} register={register} />
                                                        </div> 
                                                        <br></br>
                                                        <div style={ seedInterestFlags.isSeedTesting ? {display: ''} : {display: 'none'}}>
                                                            <SeedTesting useOrgSeedInterest={useOrgSeedInterest} register={register} />
                                                        </div> 
                                                        <br></br>
                                                        <div style={ seedInterestFlags.isOther ? {display: ''} : {display: 'none'}}>
                                                            <OtherService useOrgSeedInterest={useOrgSeedInterest} register={register} />
                                                        </div>                                  
                                                        
                                                        <br></br>
                                                        <button className="btn btn-primary" type="submit">Update Seed Interests</button>   
                                                        <br></br>
                                                    </Col>
                                                </Row>
                                                <br></br>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Tab>
                            
                                <Tab eventKey="commPrefs" title="Communication Preferences">
                                    <Row>
                                        <Col>
                                            <Form onSubmit={handleSubmit(updatePreferences)}>
                                                <br></br>
                                                <h4>Communication Preferences</h4>
                                                <Row>
                                                    <Col sm={9}>
                                                        <Row >                     
                                                            <Col sm={4}>
                                                                <Form.Group controlId="formUserCommPref">
                                                                <Form.Check type="checkbox" {...register(EUserProfileKeys.userNewsletterSubscribed)} name={EUserProfileKeys.userNewsletterSubscribed} label="Subscribe to newsletter ?" />
                                                            </Form.Group>
                                                            </Col>
                                                        </Row>

                                                        <br></br>
                                                        <button className="btn btn-primary" type="submit">Update Preferences</button>
                                                    </Col>
                                                </Row>
                                                <br></br>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Tab>

                                <Tab eventKey="badges" title="Badges">
                                    <Row>
                                        <Col>
                                            <div>
                                                <br></br>
                                                <h4>Badges</h4>
                                                <Row>
                                                    <Col sm={9}>
                                                        {
                                                            (badgeJSX && badgeJSX.length>0) ? badgeJSX : <p>You need to earn badges by completing course modules in moodle</p>
                                                        }
                                                        <br></br>
                                                    </Col>
                                                </Row>
                                                <br></br>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </LoadingOverlay>
                    </Container>
                }
            </Layout>
        }
        </>
  );
};



export default UserProfile;