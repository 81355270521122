import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormControl, Image, Row, Tab, Tabs } from "react-bootstrap";
import { EResearchOrgDataFields } from "../../models/cmsModels";

const ResearchOrganisation = ({useOrgSeedInterest, register}) => {

    return (
        <Row >
            <h3>Research</h3>
            <div className="panel panel-default">
                <div className="panel-body">
                
                <Col sm={9}>
                    <br></br>
                    <Row>
                        <Form.Group controlId="formUserResearchOrgAdditionalInfo">
                            <Form.Label>Description of research</Form.Label>
                            <Form.Control as="textarea" disabled={useOrgSeedInterest} rows={3} placeholder="e.g. type your description here" {...register(EResearchOrgDataFields.ResearchOrgDescription)} />
                        </Form.Group>
                        <br></br>
                    </Row>
                </Col>
                </div>
            </div>
        </Row>
    )
}

export default ResearchOrganisation;