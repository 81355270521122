import axios, { AxiosRequestConfig } from 'axios';
import { showComposedMessage } from '../helpers/serviceHelper';
import { ISubscriptionResponse } from '../models';

const url = process.env.GATSBY_MAILCHIMP_URL || 'https://florabank.azure-api.net/uat/MailChimpHandler/';
enum EOperation {
  GetSubscriptionStatus = 'GetSubscriptionStatus',
  Subscribe = 'Subscribe',
  Unubscribe = 'Unsubscribe'
}

const getConfig = (accessToken: string) : AxiosRequestConfig => (
  accessToken ?
    {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    } : {}
  );

export const getCommunicationPreferences = async (accessToken: string, email: string) : Promise<ISubscriptionResponse> => {
  email = email.replace('+', '%2B');

  const apiUrl = `${url}${EOperation.GetSubscriptionStatus}?address=${email}`;
  const config = getConfig(accessToken);

  let response: any;
  
  try {
    response = await axios.get(apiUrl, config);
    return response.data;
  }
  catch (ex: any) {
    showComposedMessage('getCommunicationPreferences', apiUrl, ex);
  }
};

export const setCommunicationPreferences = async (accessToken: string, email: string, firstName: string, lastName: string, flag: boolean, endFn: Function) : Promise<ISubscriptionResponse> => {
  const operation = flag ? EOperation.Subscribe : EOperation.Unubscribe;

  email = email.replace('+', '%2B');

  let response: any;
  
  const apiUrl = `${url}${operation}?address=${email}&fname=${firstName}&lname=${lastName}`;
  const config = getConfig(accessToken);

  try {
    response = await axios.post(apiUrl, null, config);
    return response.data;
  }
  catch (ex: any) {
    showComposedMessage('setCommunicationPreferences', apiUrl, ex);
  }
  finally {
    if (endFn) endFn();
  }
};
