import React from "react";
import { Col, Form, FormControl, Row } from "react-bootstrap";

import { getCheckBoxField } from "../../helpers/uiHelper";
import { ENurseryDataFields } from "../../models/cmsModels";

const Nursery = ({useOrgSeedInterest, register}) => {

    return (
        <Row >
            <h3>Nursery</h3>
            <div className="panel panel-default">
                <div className="panel-body">
                <Col sm={9}>
                    <br></br>
                    <Row>
                        <Form.Label>States where services provided</Form.Label>
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInNT, 'NT', useOrgSeedInterest)}
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInWA, 'WA', useOrgSeedInterest)}
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInSA, 'SA', useOrgSeedInterest)}
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInVIC, 'VIC', useOrgSeedInterest)}
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInTAS, 'TAS', useOrgSeedInterest)}
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInACT, 'ACT', useOrgSeedInterest)}
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInNSW, 'NSW', useOrgSeedInterest)}
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInQLD, 'QLD', useOrgSeedInterest)}
                        <br></br>
                        <Form.Group controlId="formUserNurseryCapaciy">
                            <Form.Label>Nursery Capacity</Form.Label>
                            <FormControl disabled={useOrgSeedInterest} type="text" name={ENurseryDataFields.nurseryCapacity} placeholder="What is the total plant capacity of the nursery ? (eg. 5000 plants)" {...register(ENurseryDataFields.nurseryCapacity)} />
                        </Form.Group>
                    </Row>
                </Col>
                </div>
            </div>
        </Row>
    )
}

export default Nursery;