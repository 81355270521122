import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormControl, Image, Row, Tab, Tabs } from "react-bootstrap";
import { getCheckBoxField } from "../../helpers/uiHelper";
import { EOtherServiceDataFields } from "../../models/cmsModels";

const OtherService = ({useOrgSeedInterest, register}) => {

    return (
        <Row >
            <h3>Other Service</h3>
            <div className="panel panel-default">
                <div className="panel-body">
                <Col sm={9}>
                    <br></br>
                    <Row>
                        <Form.Label>States where services provided</Form.Label>
                        {getCheckBoxField(register, EOtherServiceDataFields.otherServiceNT, 'NT', useOrgSeedInterest)}
                        {getCheckBoxField(register, EOtherServiceDataFields.otherServiceWA, 'WA', useOrgSeedInterest)}
                        {getCheckBoxField(register, EOtherServiceDataFields.otherServiceSA, 'SA', useOrgSeedInterest)}
                        {getCheckBoxField(register, EOtherServiceDataFields.otherServiceVIC, 'VIC', useOrgSeedInterest)}
                        {getCheckBoxField(register, EOtherServiceDataFields.otherServiceTAS, 'TAS', useOrgSeedInterest)}
                        {getCheckBoxField(register, EOtherServiceDataFields.otherServiceACT, 'ACT', useOrgSeedInterest)}
                        {getCheckBoxField(register, EOtherServiceDataFields.otherServiceNSW, 'NSW', useOrgSeedInterest)}
                        {getCheckBoxField(register, EOtherServiceDataFields.otherServiceQLD, 'QLD', useOrgSeedInterest)}
                        <br></br>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" disabled={useOrgSeedInterest} rows={3} placeholder="eg. Seed Cleaning" {...register(EOtherServiceDataFields.otherServiceDescription)} />
                        </Form.Group>
                        <br></br>
                    </Row>
                </Col>
                </div>
            </div>
        </Row>
    )
}

export default OtherService;